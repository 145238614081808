import Size from "@/mixins/Size/Size";

export default {
    mixins: [Size],
    props: {
        field: {
            type: Object
        },
        frontend: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object | String | Array
        },
        suppressDisabled: {
            type: Boolean,
            default: false
        },
        state: {
            type: Boolean,
            default: null
        }
    },
    data() {
        return {
            localValue: this.value,
            valid: true
        };
    },
    watch: {
        value: {
            handler(val) {
                this.localValue = val;
            },
            immediate: true
        },
        localValue: {
            handler(val) {
                this.$emit("input", val);
            },
            immediate: true
        }
    },
    computed: {
        defaultValue() {
            if (this.field.field_default_value) {
                return this.field.field_default_value;
            }
            return null;
        },
        required() {
            return this.field.field_required == 1;
        },
        disabled() {
            return (
                this.field.field_disabled == 1 && this.suppressDisabled !== true
            );
        }
    }
};
