<template>
    <div class="forms-field-type forms-field-input">
        <template v-if="frontend">
            <b-input
                type="number"
                :placeholder="field.field_placeholder"
                :state="state"
                v-model="localValue"
            />
        </template>
        <template v-else>
            <b-input
                type="number"
                :placeholder="field.field_placeholder"
                :size="size"
            />
        </template>
    </div>
</template>

<script>
import FieldType from "@/mixins/FieldType/FieldType";

export default {
    name: "CounterField",
    mixins: [FieldType]
};
</script>
