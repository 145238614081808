<template>
    <div class="forms-field-type forms-field-input">
        <template v-if="frontend">
            <b-form-rating
                :stars="stars"
                :size="size"
                v-model="localValue"
            ></b-form-rating>
        </template>
        <template v-else>
            <b-form-rating :stars="stars" :size="size"></b-form-rating>
        </template>
    </div>
</template>

<script>
import FieldType from "@/mixins/FieldType/FieldType";

export default {
    name: "StarRatingField",
    mixins: [FieldType],
    computed: {
        stars() {
            return this.field.field_settings.stars ?? 5;
        }
    }
};
</script>
