<template>
    <div class="forms-field-type forms-field-parting-line">
        <template v-if="frontend">
            <hr />
        </template>
        <template v-else>
            <hr />
        </template>
    </div>
</template>

<script>
import FieldType from "@/mixins/FieldType/FieldType";

export default {
    name: "PartingLineField",
    mixins: [FieldType]
};
</script>
