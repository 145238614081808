<template>
    <div class="forms-field-type forms-field-textare">
        <template v-if="frontend">
            <b-textarea
                :placeholder="field.field_placeholder"
                v-model="localValue"
            />
        </template>
        <template v-else>
            <b-textarea :placeholder="field.field_placeholder" :size="size" />
        </template>
    </div>
</template>

<script>
import FieldType from "@/mixins/FieldType/FieldType";

export default {
    name: "TextareaField",
    mixins: [FieldType]
};
</script>
