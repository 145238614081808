<template>
    <div class="forms-field-type forms-field-input">
        <template v-if="frontend">
            <b-form-checkbox-group
                :options="field.field_options"
                v-model="localValue"
            ></b-form-checkbox-group>
        </template>
        <template v-else>
            <b-form-checkbox-group
                :options="field.field_options"
            ></b-form-checkbox-group>
        </template>
    </div>
</template>

<script>
import FieldType from "@/mixins/FieldType/FieldType";

export default {
    name: "MultipleChoiceField",
    mixins: [FieldType],
    props: {
        value: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    watch: {
        value: {
            handler(val) {
                if (!val) {
                    this.localValue = [];
                }
            }
        }
    }
};
</script>
