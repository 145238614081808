<template>
    <div class="forms-field-type forms-field-textare">
        <template v-if="frontend">
            <div v-html="field.field_default_value"></div>
        </template>
        <template v-else>
            <vue-editor
                v-model="field.field_default_value"
                :editor-toolbar="customToolbar"
            ></vue-editor>
        </template>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

import FieldType from "@/mixins/FieldType/FieldType";

export default {
    name: "WYSIWYGField",
    mixins: [FieldType],
    components: {
        VueEditor
    },
    data() {
        return {
            customToolbar: [
                [
                    {
                        header: [false, 4, 5]
                    }
                ],
                ["bold", "italic", "underline", "strike"],
                [
                    {
                        align: ""
                    },
                    {
                        align: "center"
                    },
                    {
                        align: "right"
                    },
                    {
                        align: "justify"
                    }
                ],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ indent: "-1" }, { indent: "+1" }],
                ["link"],
                [
                    {
                        color: [
                            "#000000",
                            "#e60000",
                            "#ff9900",
                            "#ffff00",
                            "#008a00",
                            "#0066cc",
                            "#9933ff",
                            "#ffffff",
                            "#facccc",
                            "#ffebcc",
                            "#ffffcc",
                            "#cce8cc",
                            "#cce0f5",
                            "#ebd6ff",
                            "#bbbbbb",
                            "#f06666",
                            "#ffc266",
                            "#ffff66",
                            "#66b966",
                            "#66a3e0",
                            "#c285ff",
                            "#888888",
                            "#a10000",
                            "#b26b00",
                            "#b2b200",
                            "#006100",
                            "#0047b2",
                            "#6b24b2",
                            "#444444",
                            "#5c0000",
                            "#663d00",
                            "#666600",
                            "#003700",
                            "#002966",
                            "#3d1466",
                            "custom-color"
                        ]
                    }
                ]
            ]
        };
    },
    watch: {
        value: {
            handler(val) {
                this.localValue = null;
            },
            immediate: true
        }
    }
};
</script>

<style>
.ql-snow .ql-editor h4 {
    font-size: 1.5rem;
}

.ql-snow .ql-editor h5 {
    font-size: 1.25rem;
}
</style>
