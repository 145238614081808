<template>
    <div class="forms-field-type forms-field-input">
        <template v-if="frontend">
            <b-select :options="options" v-model="localValue"></b-select>
        </template>
        <template v-else>
            <b-select :options="options"></b-select>
        </template>
    </div>
</template>

<script>
import FieldType from "@/mixins/FieldType/FieldType";

export default {
    name: "DropdownField",
    mixins: [FieldType],
    data() {
        return {
            options: [
                { value: null, text: "keine Angabe" },
                { value: "prof", text: "Prof." },
                { value: "dr", text: "Dr." }
            ]
        };
    }
};
</script>
