<template>
    <div class="forms-field-type forms-field-input">
        <template v-if="frontend">
            <b-form-radio-group
                :options="options"
                v-model="localValue"
            ></b-form-radio-group>
        </template>
        <template v-else>
            <b-form-radio-group :options="options"></b-form-radio-group>
        </template>
    </div>
</template>

<script>
import FieldType from "@/mixins/FieldType/FieldType";

export default {
    name: "GenderField",
    mixins: [FieldType],
    data() {
        return {
            options: [
                { value: "m", text: "männlich" },
                { value: "w", text: "weiblich" },
                { value: "d", text: "divers" }
            ]
        };
    }
};
</script>
