export default {
    data() {
        return {
            validation: {}
        };
    },
    methods: {
        getValidationStateForField(fieldId) {
            const feedbackId = Object.keys(this.validation).find(
                key => key == fieldId
            );
            if (feedbackId) {
                return false;
            }
            return null;
        },
        getValidationFeedbackForField(fieldId) {
            const feedbackId = Object.keys(this.validation).find(
                key => key == fieldId
            );
            if (feedbackId) {
                var feedback = this.validation[feedbackId];
                // For FormsPresenter
                if (this.form && this.form.fields) {
                    const field = this.form.fields.find(
                        item => item.id == fieldId
                    );
                    if (field) {
                        feedback = feedback.map(fb =>
                            fb.replace(fieldId, field.field_label)
                        );
                    }
                }
                // For FormsAnswer
                else if (this.editIoFields) {
                    const field = this.editIoFields.find(
                        item => item.id == fieldId
                    );
                    feedback = feedback.map(fb =>
                        fb.replace(fieldId, field.field_label)
                    );
                }

                return feedback;
            }
            return [];
        },
        resetValidationFeedback() {
            this.validation = {};
        }
    }
};
