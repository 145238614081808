<template>
    <div class="forms-field-type forms-field-input">
        <template v-if="frontend">
            <b-select
                :options="field.field_options"
                :state="state"
                v-model="localValue"
            ></b-select>
        </template>
        <template v-else>
            <b-select
                :options="field.field_options"
                :value="defaultValue"
            ></b-select>
        </template>
    </div>
</template>

<script>
import FieldType from "@/mixins/FieldType/FieldType";

export default {
    name: "DropdownField",
    mixins: [FieldType],
    watch: {
        value: {
            handler(val) {
                if (!val) {
                    this.localValue = this.defaultValue;
                }
            },
            immediate: true
        }
    }
};
</script>
