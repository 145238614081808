<template>
    <div class="forms-field-type forms-field-input">
        <template v-if="frontend">
            <b-form-radio-group
                :disabled="disabled"
                :options="field.field_options"
                v-model="localValue"
                :checked="defaultValue"
            ></b-form-radio-group>
        </template>
        <template v-else>
            <b-form-radio-group
                :disabled="disabled"
                :options="field.field_options"
                :checked="defaultValue"
            ></b-form-radio-group>
        </template>
    </div>
</template>

<script>
import FieldType from "@/mixins/FieldType/FieldType";

export default {
    name: "SingleChoiceField",
    mixins: [FieldType],
    watch: {
        value: {
            handler(val) {
                if (!val) {
                    this.localValue = this.defaultValue;
                }
            },
            immediate: true
        }
    }
};
</script>
